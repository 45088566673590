import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "table",
      "style": {
        "position": "relative"
      }
    }}>{`Table`}</h1>
    <p>{`The component presents data distributed in lines and columns, displaying them in a logical order that allows easy reading, comparison and scan of the information.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`Columns where ordinating is possible, should present the sort icon in the right side of the label.`}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "/components/paginator"
        }}>{`Paginator`}</a>{` component must be presented only for large data sets.`}</li>
    </ul>
    <h2 {...{
      "id": "data-table",
      "style": {
        "position": "relative"
      }
    }}>{`Data table`}</h2>
    <p>{`A table that automatically generates rows based on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`columns`}</code>{` prop and gives support to sorting and loading state.`}</p>
    <Demo src='pages/components/table/DataTable' mdxType="Demo" />
    <h2 {...{
      "id": "paged-table",
      "style": {
        "position": "relative"
      }
    }}>{`Paged table`}</h2>
    <p>{`A DataTable that includes a pagination component on the footer.`}</p>
    <Demo src='pages/components/table/PagedTable' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      